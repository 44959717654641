
import { parsePrice } from '@/shared/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Component({})
export default class IvoiceItemRow extends Vue {
    @Prop(Object) public item!: any;
    @Prop(Array) public itemsHeaders!: any;
    public parsePrice = parsePrice;
}
